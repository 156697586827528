export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
// export const USER_DETAIL = "USER_DETAIL";
// export const USERS_DETAIL ="USERS_DETAIL"
export const SIDE_BAR = "SIDE_BAR";
export const USER_LOGIN = "USER_LOGIN";
// export const INIT_LOGIN = "INIT_LOGIN";
export const LOG_OUT = "LOG_OUT";
export const USER_LOGIN_OTP ="USER_LOGIN_OTP";
export const VALIDATE_LOGIN ="VALIDATE_LOGIN"
export const VERIFY_ACCOUNT ="VERIFY_ACCOUNT"
//common
export const GET_COUNTRY = 'GET_COUNTRY'
export const GET_STATE = 'GET_STATE';
export const GET_CITY = 'GET_CITY'
export const GET_ROLE_LIST ='GET_ROLE_LIST'
export const GET_ORG_LIST ='GET_ORG_LIST'


// user management 
 export const ADD_USER = 'ADD_USER'
 export const USER_LIST ='USER_LIST'
 export const DELETE_USER ='DELETE_USER'
 export const VIEW_USER ='VIEW_USER'
 export const EDIT_USER ='EDIT_USER'
 export const USER_PROFILE ='USER_PROFILE'


 //product

 export const PRODUCT_LIST ='PRODUCT_LIST'
 export const ADD_PRODUCT ='ADD_PRODUCT'
 export const EDIT_PRODUCT = 'EDIT_PRODUCT'
 export const DELETE_PRODUCT = 'DELETE_PRODUCT'
 export const VIEW_PRODUCT ='VIEW_PRODUCT'

 //store
 export const STORE_LIST ='STORE_LIST'
 export const STORE_STATUS_APPROVE = 'STORE_STATUS_APPROVE'
 export const DELETE_STORE ='DELETE_STORE'
 export const ADD_STORE = 'ADD_STORE' 
 export const EDIT_STORE ='EDIT_STORE'
 export const STORE_VIEW ='STORE_VIEW'

 //order

 export const ORDER_LIST = "ORDER_LIST"
 export const ORDER_STATUS ="ORDER_STATUS"

 // product category
 export const GET_PRODUCT_CAT ="GET_PRODUCT_CAT"
 export const PRODUCT_STATUS = "PRODUCT_STATUS"
 export const ADD_PRODUCT_CAT = "ADD_PRODUCT_CAT"
 export const VIEW_PRODUCT_CAT = "VIEW_PRODUCT_CAT"
 export const UPDATE_PRODUCT_CAT = "UPDATE_PRODUCT_CAT"

//manager
export const STORE_MANAGER_LIST ="STORE_MANAGER_LIST"

//product Review 
export const PRODUCT_REVIEW = "PRODUCT_REVIEW"
export const DELETE_PRODUCT_REVIEW = "DELETE_PRODUCT_REVIEW"

//favorite product
export const FAVORITE_PRODUCT = "FAVORITE_PRODUCT"
export const DELETE_FAVORITE_PRODUCT = "DELETE_FAVORITE_PRODUCT"

//Rider
export const ADD_RIDER = "ADD_RIDER"

export const GET_RIDERS ="GET_RIDERS"
export const DELETE_RIDERS ="DELETE_RIDERS"
export const EDIT_RIDERS = "EDIT_RIDERS"
export const VIEW_RIDERS ="VIEW_RIDERS"

export const TOOKAN_CREATE_TASK ="TOOKAN_CREATE_TASK";

export const BANNER_LIST = "BANNER_LIST"
export const DELETE_BANNER ="DELETE_BANNER"
export const ADD_BANNER = "ADD_BANNER"
export const EDIT_BANNER = "EDIT_BANNER"
export const VIEW_BANNER = "VIEW_BANNER"
 
//coupon 
export const COUPON_LIST  = "COUPON_LIST"

//faq
export const FAQ_LIST  = "FAQ_LIST"
export const FAQ_DELETE  = "FAQ_DELETE"
export const FAQ_EDIT = "FAQ_EDIT"
export const FAQ_VIEW = "FAQ_VIEW"
export const FAQ_ADD = "FAQ_ADD"

//enquiry
export const ENQUIRY_LIST  = "ENQUIRY_LIST"
export const DELETE_ENQUIRY  = "FAQ_DELETE"

//common



//get Store Manager List



// user management 
 

