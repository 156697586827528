import { ADD_USER,DELETE_USER, USER_LIST,EDIT_USER, VIEW_USER, USER_PROFILE} from "../actions/types";
// import Cookies from "js-cookie";

const initialState = {
  createUser: null,
  userList: null,
  deluser : null,
  userView: null,
  userUpdate : null,
  userProfile : null,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case ADD_USER:
      return {
        ...state,
        createUser: action.payload,
      };

      case USER_LIST:
        return{
          ...state,
          userList:action.payload
        }

        case DELETE_USER:
          return{
            ...state,
            deluser:action.payload
          }
          case VIEW_USER:
            return{
              ...state,
              userView:action.payload
            }
            case EDIT_USER:
              return{
                ...state,
                userUpdate:action.payload
              }
              case USER_PROFILE:
                return{
                  ...state,
                  userProfile:action.payload
                }
      default:
        return state;
  
};
}
export default loginReducer;
