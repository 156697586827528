import { ORDER_LIST,ORDER_STATUS, ADD_PRODUCT } from "../actions/types";
// import Cookies from "js-cookie";

const initialState = {
  orderList: null,
  orderStatus: null
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_LIST:
      return {
        ...state,
        orderList: action.payload,
      };
      case ORDER_STATUS:
        return {
          ...state,
          orderStatus: action.payload,
        };

    default:
      return state;
  }
};
export default orderReducer;
