import { USER_LOGIN ,USER_LOGIN_OTP,VALIDATE_LOGIN,VERIFY_ACCOUNT} from "../actions/types";
// import Cookies from "js-cookie";

const initialState = {
  userLogin: null,
  verifyAcc: null,
  otp: null
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case USER_LOGIN:
     
      //Cookies.set("access_token", jsonData.data.access_token,{ expires: 4 , domain:'.salescurve.com'});
     // Cookies.set("access_token", action.data.access_token,{ expires: 4 });
      return {
        ...state,
        userLogin: action.payload,
      };
      
      case USER_LOGIN_OTP :
        return {
          ...state,
          otp: action.payload,
        };
       
      case VALIDATE_LOGIN :
        return {
          ...state,
          validateOtp: action.payload
        }
        case VERIFY_ACCOUNT :
          return{
            ...state,
            verifyAcc: action.payload
          }
      default:
        return state;
  
};
}
export default loginReducer;
