import { STORE_LIST, STORE_MANAGER_LIST, STORE_STATUS_APPROVE,ADD_STORE,STORE_VIEW, DELETE_STORE, EDIT_STORE } from "../actions/types";
// import Cookies from "js-cookie";

const initialState = {
  storeCreate: null,
  storeList: null,
  storeManagerList: null,
  storeDelete: null,
  statusApproval: null,
  storeView: null ,
  storeEdit: null,
};

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_LIST:
      return {
        ...state,
        storeList: action.payload,
      };
      case DELETE_STORE:
        return {
          ...state,
          storeDelete: action.payload,
        };
        case STORE_STATUS_APPROVE:
          return {
            ...state,
            statusApproval: action.payload,
          };
          case ADD_STORE :
            return{
              ...state,
              storeCreate : action.payload
            }
            case STORE_VIEW :
              return{
                ...state,
                storeView: action.payload
              }
              case EDIT_STORE:
                return{
                  ...state,
                  storeEdit : action.payload
                }

    case STORE_MANAGER_LIST:
      return {
        ...state,
        storeManagerList: action.payload,
      };

    default:
      return state;
  }
};
export default storeReducer;
