import {
  BANNER_LIST,
  DELETE_BANNER,
  EDIT_BANNER,
  ADD_BANNER,
  VIEW_BANNER,
} from "../actions/types";

const initialState = {
  bannerlist: null,
  bannerDelete: null,
  bannerEdit: null,
  bannerAdd: null,
  bannerView: null,
};

const BannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case BANNER_LIST:
      return {
        ...state,
        bannerlist: action.payload,
      };
    case DELETE_BANNER:
      return {
        ...state,
        bannerDelete: action.payload,
      };
    case ADD_BANNER:
      return {
        ...state,
        bannerAdd: action.payload,
      };
    case EDIT_BANNER:
      return {
        ...state,
        bannerEdit: action.payload,
      };
    case VIEW_BANNER:
      return {
        ...state,
        bannerView: action.payload,
      };

    default:
      return state;
  }
};
export default BannerReducer;
