export const getToken = () =>{
    let allcookies = document.cookie.split(';');
let token =""
   for(let i=0; i<allcookies.length ; i++){
   if( allcookies[i].split('=')[0] === 'access_token')
   {
    token= allcookies[i].split('=')[1] ;
   }
   }
return token
}