import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";
import "./App.css";
import "./index.css";
import { getToken } from "./utils/token";

const Otp = React.lazy(() => import("components/Login/Otp"));
const Login = React.lazy(() => import("components/Login/LoginMain"));
const Login_email = React.lazy(() => import("components/Login/Login"));
const Rootlayout = React.lazy(() => import("./Layout/RootLayout/RootLayout"));
const ConfirmPassword = React.lazy(() =>
  import("./components/Login/ForgotPassword")
);

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

function App(props) {
  const [state, setState] = useState("");
  useEffect(() => {
    let token;
    token = getToken();
    console.log("ppppooo", token);
    setState(token);
  });

  // useEffect(()=>{
  //   if(props.error !== null){
  //     console.log("props.error.message",props.error.message)
  //     if(props.error.message ===  "Network Error")
  //     {
  //       window.location.href ="/"
  //     }
  //   }

  // },[props.error])
  return (
    <Router history={history} basename={process.env.PUBLIC_URL}>
      <Suspense fallback={loading()}>
        <Switch>
          <Route
            exact
            path="/activate"
            name="activate"
            render={(props) => <ConfirmPassword {...props} />}
          />
          <Route
            exact
            path="/"
            name="login"
            render={(props) => <Login {...props} />}
          />
          <Route
          
            path="/login/email"
            name="login"
            render={(props) => <Login_email {...props} />}
          />

          <Route
            
            path="/login/otp"
            name="otp"
            render={(props) => <Otp {...props} />}
          />
          <Route
            path="/"
            name="Main Page"
            render={(props) => <Rootlayout {...props} />}
          />
        </Switch>
      </Suspense>
    </Router>
  );
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => ({
  error: state.errorResponse.error,
  userLogin: state.loginResponse.userLogin,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
