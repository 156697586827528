import {
  PRODUCT_LIST,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  PRODUCT_STATUS,
  VIEW_PRODUCT
} from "../actions/types";

const initialState = {
  productCreate: null,
  productList: null,
  productEdit: null,
  productDelete: null,
  productView : null,
  productStatus: null
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        ...state,
        productCreate: action.payload,
      };

    case PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload,
      };
    case EDIT_PRODUCT:
      return {
        ...state,
        productEdit: action.payload,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        productDelete: action.payload,
      };
      case VIEW_PRODUCT:
        return {
          ...state,
          productView: action.payload,
        };
        case PRODUCT_STATUS:
          return{
            ...state,
            productStatus: action.payload
          }
    default:
      return state;
  }
};
export default productReducer;
