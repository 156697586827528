import {
    ENQUIRY_LIST,
    DELETE_ENQUIRY
   } from "../actions/types";
   
   const initialState = {
     enqlist: null,
     enqDelete: null
      
   };
   
   const enquiryReducer = (state = initialState, action) => {
     switch (action.type) {
       case ENQUIRY_LIST:
         return {
           ...state,
           enqlist: action.payload,
         };
         case DELETE_ENQUIRY:
            return {
              ...state,
              enqDelete: action.payload,
            };
       
      
       default:
         return state;
     }
   };
   export default enquiryReducer;
   