import {
    ADD_RIDER,
    GET_RIDERS,
    TOOKAN_CREATE_TASK,
    EDIT_RIDERS,
    DELETE_RIDERS,
    VIEW_RIDERS,
    
  } from "../actions/types";
  
  const initialState = {
    riderCreate: null,
    ridersList: null,
    ridersDelete: null,
    createTask: null,
    riderEdit: null,
    riderView: null
  };
  
  const riderReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_RIDER:
        return {
          ...state,
          riderCreate: action.payload,
        };
        case GET_RIDERS:
            return{
                ...state,
                ridersList: action.payload
            }
            case DELETE_RIDERS:
              return{
                  ...state,
                  ridersDelete: action.payload
              }
              case TOOKAN_CREATE_TASK:
                return{
                  ...state,
                  createTask: action.payload

                }
                case EDIT_RIDERS:
                return{
                  ...state,
                  riderEdit: action.payload

                }
                case VIEW_RIDERS:
                  return{
                    ...state,
                    riderView: action.payload
  
                  }
  
     
      default:
        return state;
    }
  };
  export default riderReducer;
  