import {
   FAVORITE_PRODUCT,
   DELETE_FAVORITE_PRODUCT
  } from "../actions/types";
  
  const initialState = {
    favProductList: null,
    favProductDelete :  null
  };
  
  const favoriteProductReducer = (state = initialState, action) => {
    switch (action.type) {
      case FAVORITE_PRODUCT:
        return {
          ...state,
          favProductList: action.payload,
        };
        case DELETE_FAVORITE_PRODUCT:
          return {
            ...state,
            favProductDelete: action.payload,
          };
     
      default:
        return state;
    }
  };
  export default favoriteProductReducer;
  