import {
  GET_COUNTRY,
  GET_STATE,
  GET_CITY,
  GET_ROLE_LIST,
  GET_ORG_LIST,
} from "../actions/types";

const initialState = {
  country: null,
  state: null,
  city: null,
  orgList: null,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    case GET_STATE:
      return {
        ...state,
        state: action.payload,
      };
    case GET_CITY:
      return {
        ...state,
        city: action.payload,
      };

    case GET_ROLE_LIST:
      return {
        ...state,
        roles: action.payload,
      };
    case GET_ORG_LIST:
      return {
        ...state,
        orgList: action.payload,
      };

    default:
      return state;
  }
};
export default commonReducer;
