import {
    FAQ_LIST,
    FAQ_DELETE,
    FAQ_EDIT,
    FAQ_ADD,
    FAQ_VIEW
  
   } from "../actions/types";
   
   const initialState = {
     faqList: null,
     faqDelete: null,
     faqAdd: null,
     faqEdit : null,
     faqView : null
      
   };
   
   const faqReducer = (state = initialState, action) => {
     switch (action.type) {
       case FAQ_LIST:
         return {
           ...state,
           faqList: action.payload,
         };
         case FAQ_DELETE:
            return {
              ...state,
              faqDelete: action.payload,
            };
            case FAQ_ADD:
                return {
                  ...state,
                  faqAdd: action.payload,
                };
                case FAQ_EDIT:
                    return {
                      ...state,
                      faqEdit: action.payload,
                    };
                    case FAQ_VIEW:
                        return {
                          ...state,
                          faqView: action.payload,
                        };
    
       
      
       default:
         return state;
     }
   };
   export default faqReducer;
   