import {
    SIDE_BAR,
  } from "../actions/types";
  
  const initialState = {
    sidebarData: null,
  };
  
  const SideBarReducer = (state = initialState, action) => {
    switch (action.type) {
      case SIDE_BAR:
     //   localStorage.setItem("token", action.payload.token);
        return {
          ...state,
          sidebarData: action.payload
        };
      
       
  
      default:
        return state;
    }
  };
  export default SideBarReducer;
  