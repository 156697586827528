import { PRODUCT_REVIEW, DELETE_PRODUCT_REVIEW } from "../actions/types";

const initialState = {
  productReviewlist: null,
  productReviewDelete: null,
};

const productReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_REVIEW:
      return {
        ...state,
        productReviewlist: action.payload,
      };
    case DELETE_PRODUCT_REVIEW:
      return {
        ...state,
        productReviewDelete: action.payload,
      };

    default:
      return state;
  }
};
export default productReviewReducer;
